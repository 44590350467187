@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  min-height: 64px;
  margin-top: 15px;

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &.without-bottom-slots {
    justify-content: center;
    margin-top: 0;
  }

  @include min-428-break {
    width: 100%;
    min-height: 80px;
    margin-top: 18px;
  }

  @include min-744-break {
    width: 100%;
    min-height: 64px;
  }

  .top-slot {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;

    &.with-bottom-slot {
      bottom: 8px;
    }
    &__left {
      display: flex;
      align-items: center;
      gap: 8px;
      position: relative;
      left: 8px;
      flex: 2;

      @include min-428-break {
        left: 0;
      }

      @include min-744-break {
        left: 8px;
      }
      .item-info {
        position: relative;
        left: 7px;
        display: flex;
        flex: 1;
        flex-direction: column;
        gap: 1px;

        &.secondary {
          flex-direction: column-reverse;
        }

        @include min-428-break {
          left: 20px;
        }

        @include min-744-break {
          left: 7px;
        }

        .title {
          color: var(--cst-cards-item-page-newprice);
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          width: 98%;
          max-width: 126px;
          overflow: hidden;

          @include typography-base;
          @include weight-semi-bold;

          @include min-428-break {
            max-width: 196px;
            @include typography-m;
          }
        }

        .age {
          color: var(--cst-cards-shoping-item-title);
          max-width: 128px;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
          overflow: hidden;

          &.secondary {
            color: var(--cst-cards-shoping-item-subtitle);
            @include typography-s;
            @include weight-semi-bold;

            @include min-428-break {
              @include typography-base;
            }

            @include min-744-break {
              @include typography-s;
            }
          }

          @include typography-xs;
          @include weight-medium;

          @include min-428-break {
            @include typography-s;
          }

          @include min-744-break {
            @include typography-s;
          }
        }

        .subtitle {
        }

        .price {
          display: flex;
          align-items: center;
          gap: 4px;

          .old-price {
            color: var(--cst-cards-item-page-oldprice);

            @include typography-xs;
            @include weight-semi-bold;

            @include min-428-break {
              @include typography-s;
            }
          }

          .current-price,
          .status {
            color: var(--cst-cards-item-page-newprice);

            @include typography-base;
            @include weight-bold;

            @include min-428-break {
              @include typography-m;
            }

            @include min-744-break {
              @include typography-base;
            }
          }

          .current-price {
            @include typography-s;
            @include weight-semi-bold;

            @include min-428-break {
              @include typography-base;
            }

            @include min-744-break {
              @include typography-base;
            }
          }

          .status {
            @include weight-semi-bold;
          }
        }
      }
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 12px;
      justify-content: flex-end;
      flex: 1;
    }
  }

  .bottom-slot {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__left {
      flex-grow: 1;
    }

    &__right {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}

.properties {
  display: flex;
  align-items: center;
  width: max-content;
  background: var(--cst-cards-shoping-item-background-tags);
  border-radius: var(--cornerradius8);
  padding: 8px;
  gap: 6px;

  @include min-428-break {
    gap: 8px;
  }

  @include min-744-break {
    gap: 6px;
  }

  .category,
  .rarity {
    @include singleFixedSize(14px);

    @include min-428-break {
      @include singleFixedSize(18px);
    }

    @include min-744-break {
      @include singleFixedSize(14px);
    }
  }
}

.item {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--cornerradius12);

  &.item-unavailable {
    opacity: 0.5;
  }

  @include min-428-break {
    left: 10px;
  }

  @include min-744-break {
    left: 0;
  }

  .item-variant {
    z-index: 1;
    pointer-events: none;

    @include singleFixedSize(48px);

    @include min-428-break {
      @include singleFixedSize(60px);
    }

    @include min-744-break {
      @include singleFixedSize(48px);
    }
  }

  .background {
    position: absolute;
    z-index: 0;

    @include singleFixedSize(53.33px);

    @include min-428-break {
      @include singleFixedSize(66.67px);
    }

    @include min-744-break {
      @include singleFixedSize(53.33px);
    }
  }
}

.size--l {
  &.container {
    @include min-744-break {
      width: 100%;
      min-height: 80px;
    }

    .top-slot {
      &__left {
        @include min-744-break {
          left: 0;
        }
        .item-info {
          @include min-744-break {
            left: 20px;
          }
          .title {
            &.secondary {
              @include min-744-break {
                @include typography-base;
              }
            }
            @include min-744-break {
              @include typography-s;
            }
          }

          .price {
            .current-price,
            .status {
              @include min-744-break {
                @include typography-m;
              }
            }

            .status {
              @include weight-semi-bold;
            }
          }
        }
      }
    }
  }

  .properties {
    @include min-744-break {
      gap: 8px;
    }
    .category,
    .rarity {
      @include min-744-break {
        @include singleFixedSize(18px);
      }
    }
  }

  .item {
    @include min-744-break {
      left: 10px;
    }
    .item-variant {
      @include min-744-break {
        @include singleFixedSize(60px);
      }
    }

    .background {
      @include min-744-break {
        @include singleFixedSize(66.67px);
      }
    }
  }
}
